import { render, staticRenderFns } from "./FeedbackTopComponent.vue?vue&type=template&id=0f1e6a1c&scoped=true&"
import script from "./FeedbackTopComponent.vue?vue&type=script&lang=js&"
export * from "./FeedbackTopComponent.vue?vue&type=script&lang=js&"
import style0 from "./FeedbackTopComponent.vue?vue&type=style&index=0&id=0f1e6a1c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f1e6a1c",
  null
  
)

export default component.exports